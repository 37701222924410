/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import loadable from '@loadable/component';
import { string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstLastName, getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const UserRegisteredIcon = loadable(() => import(/* webpackChunkName: "UserRegisteredIcon" */ './UserRegisteredIcon'));
const UserGuestIcon = loadable(() => import(/* webpackChunkName: "UserGuestIcon" */ './UserGuestIcon'));

const MobileSignIn = ({ imageUrl, title, type }) => {
    const ffMobileHeaderSignIn = useSelector(getFeatureFlag('is-mobile-sign-in-link-enabled'));
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const userFirstName = useSelector(getFirstLastName);

    const track = ({ eventCategory, eventAction, eventLabel }) => {
        dispatch(trackEvent({
            eventCategory,
            eventAction,
            eventLabel,
        }));
    };

    if (!ffMobileHeaderSignIn) {
        return <></>;
    }

    if (isAuthenticated && type === 'REGISTERED') {
        return <UserRegisteredIcon title={userFirstName?.firstName} icon={imageUrl} track={track} />;
    } if (type === 'GUEST' && !isAuthenticated) {
        return <UserGuestIcon title={title} icon={imageUrl} track={track} />;
    }
    return <></>;
};

MobileSignIn.defaultProps = {
    imageUrl: '',
    title: '',
    type: '',
};

MobileSignIn.propTypes = {
    imageUrl: string,
    title: string,
    type: string,
};

export default MobileSignIn;
